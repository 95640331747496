import Decimal from 'decimal.js';

export function camposDecimalParaNumber(o) {
    return Object.fromEntries(
        Object.entries(o).map(([key, value]) => [
            key,
            Decimal.isDecimal(value) ? value.toNumber() : value,
        ])
    );
}

export function zeroy(x) {
    return !x || new Decimal(x).isZero();
}

export function notZeroy(x) {
    return x && !new Decimal(x).isZero();
}

export function somatorio(lista) {
    return (lista ?? []).reduce(
        (total, x) => total.add(x || 0),
        new Decimal(0)
    );
}
