import Decimal from 'decimal.js';

/**
 * Calcula os impostos de um objeto de estado para todos os impostos que tiverem alíquota definida.
 *
 * @param {object} data Objeto de estado com os campos de impostos
 * @param {string[]|number[]} aliquotas Lista de alíquotas na mesma ordem dos impostos no campo de impostos
 * @param {string} campoImpostos Nome do campo de impostos no objeto `data`
 * @param {string[]|number[]} idEmpresas IDs da empresa de cada imposto do campo de impostos
 * @param {string|number} idEmpresaSelecionada ID da empresa selecionada pelo usuário
 * @param {string|number} baseCalculo O valor a ser usado como base de cálculo para os impostos
 */
export function calcularImpostos({
    data,
    aliquotas,
    campoImpostos,
    idEmpresas,
    idEmpresaSelecionada,
    baseCalculo,
}) {
    aliquotas = aliquotas ?? [];

    if (!data[campoImpostos]) {
        data[campoImpostos] = [];
    }

    const posicoesAusentes = aliquotas.length - data[campoImpostos].length;
    for (let i = 0; i < posicoesAusentes; i++) {
        data[campoImpostos].push('');
    }

    if (!idEmpresaSelecionada) {
        // Se ainda não há empresa selecionada, não calcula nenhum imposto
        return;
    }

    for (let i = 0; i < aliquotas.length; i++) {
        const empresaImposto = idEmpresas?.[i];
        if (empresaImposto != idEmpresaSelecionada) {
            // Zera impostos de outras empresas
            data[campoImpostos][i] = 0;
            continue;
        }

        const aliquota = aliquotas[i];
        if (
            typeof aliquota === 'number' ||
            (typeof aliquota === 'string' && aliquota)
        ) {
            // Uma alíquota foi definida, então atualiza o imposto
            data[campoImpostos][i] = new Decimal(aliquota || 0)
                .div(100)
                .mul(baseCalculo || 0)
                .toDecimalPlaces(2)
                .toNumber();
        }
    }
}
