import Decimal from 'decimal.js';
import { camposDecimalParaNumber } from './calculos';
import * as baseComum from './comum-maquinario';
import { obterConversoes as obterConversoesBase } from './comum';
import { removerAcentos } from './acentos';

export function alpineDataInsumoMaquinario(dados) {
    return {
        depreciacaoMensal: '0',
        valorMensal: '0',
        totalHorasProdutivasMes: '0',
        precoPorUnidades: [],
        ...dados,

        init() {
            this.calcularDepreciacaoMensal =
                this.calcularDepreciacaoMensal.bind(this);
            this.calcularValorMensal = this.calcularValorMensal.bind(this);
            this.calcularTotalHorasProdutivasMes =
                this.calcularTotalHorasProdutivasMes.bind(this);
            this.calcularPrecoPorUnidade =
                this.calcularPrecoPorUnidade.bind(this);
            this.selecionarUnidades = this.selecionarUnidades.bind(this);

            this.$watch(
                ['valorMaquina', 'depreciacaoAnual'],
                this.calcularDepreciacaoMensal
            );
            this.$watch(
                [
                    'depreciacaoMensal',
                    'manutencaoMensal',
                    'energiaEletricaMensal',
                    'outro',
                ],
                this.calcularValorMensal
            );
            this.$watch(
                ['quantidade', 'manutencoes', 'troca'],
                this.calcularTotalHorasProdutivasMes
            );
            this.$watch(
                ['totalHorasProdutivasMes', 'valorMensal', 'compraUnidadeId'],
                this.calcularPrecoPorUnidade
            );

            // $watch de arrays precisa ser individual
            this.$watch(
                'conversaoUnidadeOrigemIds',
                this.calcularPrecoPorUnidade
            );
            this.$watch(
                'conversaoUnidadeDestinoIds',
                this.calcularPrecoPorUnidade
            );
            this.$watch('conversaoFatores', this.calcularPrecoPorUnidade);

            this.$watch('empresa?.unidades', this.selecionarUnidades);

            this.calcularDepreciacaoMensal();
            this.calcularValorMensal();
            this.calcularTotalHorasProdutivasMes();
            this.calcularPrecoPorUnidade();
            this.selecionarUnidades();
        },

        calcularDepreciacaoMensal() {
            this.depreciacaoMensal = baseComum
                .calcularDepreciacaoMensal({
                    valorMaquina: this.valorMaquina,
                    depreciacaoAnual: this.depreciacaoAnual,
                })
                .toNumber();
        },

        calcularValorMensal() {
            this.valorMensal = baseComum
                .calcularValorMensal({
                    depreciacaoMensal: this.depreciacaoMensal,
                    manutencaoMensal: this.manutencaoMensal,
                    energiaEletricaMensal: this.energiaEletricaMensal,
                    outro: this.outro,
                })
                .toNumber();
        },

        calcularTotalHorasProdutivasMes() {
            this.totalHorasProdutivasMes = baseComum
                .calcularTotalHorasProdutivasMes({
                    quantidade: this.quantidade,
                    manutencoes: this.manutencoes,
                    troca: this.troca,
                })
                .toNumber();
        },

        selecionarUnidades() {
            const buscaUnidade = (nome) =>
                this.empresa?.unidades?.find((x) =>
                    removerAcentos(x.nome.toUpperCase()).includes(nome.toUpperCase())
                );

            const unidadeHoras = buscaUnidade('HORA-MAQUINA');
            this.compraUnidadeId = unidadeHoras?.id || null;

            const unidadeMinutos = buscaUnidade('MINUTO-MAQUINA');
            if (unidadeHoras && unidadeMinutos) {
                this.conversaoUnidadeOrigemIds = [unidadeHoras.id];
                this.conversaoUnidadeDestinoIds = [unidadeMinutos.id];
                this.conversaoFatores = ['60'];
            } else {
                this.conversaoUnidadeOrigemIds = [];
                this.conversaoUnidadeDestinoIds = [];
                this.conversaoFatores = [];
            }
        },

        calcularPrecoPorUnidade() {
            if (
                !this.totalHorasProdutivasMes ||
                new Decimal(this.totalHorasProdutivasMes).equals(0) ||
                !this.conversaoUnidadeOrigemIds ||
                !this.conversaoUnidadeDestinoIds ||
                !this.conversaoFatores
            ) {
                this.precoPorUnidades = [];
                return;
            }

            const compra = {
                quantidade: this.totalHorasProdutivasMes,
                unidadeId: this.compraUnidadeId,
            };

            const conversoes = this.obterConversoes();

            const precoPorUnidades = [
                camposDecimalParaNumber({
                    unidadeId: this.compraUnidadeId,
                    preco: baseComum
                        .calcularPrecoPorUnidade(
                            { unidadeId: this.compraUnidadeId },
                            {
                                compra,
                                conversoes,
                                valorMensal: this.valorMensal,
                            }
                        )
                        .toDecimalPlaces(3),
                }),
            ];

            for (
                let indiceConversao = 0;
                indiceConversao < this.conversaoUnidadeOrigemIds.length;
                indiceConversao++
            ) {
                const unidadeOrigemId =
                    this.conversaoUnidadeOrigemIds[indiceConversao];
                const unidadeDestinoId =
                    this.conversaoUnidadeDestinoIds[indiceConversao];
                const fator = this.conversaoFatores[indiceConversao];
                if (!unidadeOrigemId || !unidadeDestinoId || !fator) {
                    continue;
                }

                if (unidadeOrigemId == this.compraUnidadeId) {
                    precoPorUnidades.push(
                        camposDecimalParaNumber({
                            unidadeId: unidadeDestinoId,
                            preco: baseComum
                                .calcularPrecoPorUnidade(
                                    { unidadeId: unidadeDestinoId },
                                    {
                                        compra,
                                        conversoes,
                                        valorMensal: this.valorMensal,
                                    }
                                )
                                .toDecimalPlaces(3),
                        })
                    );
                } else if (unidadeDestinoId == this.compraUnidadeId) {
                    precoPorUnidades.push(
                        camposDecimalParaNumber({
                            unidadeId: unidadeOrigemId,
                            preco: baseComum
                                .calcularPrecoPorUnidade(
                                    { unidadeId: unidadeOrigemId },
                                    {
                                        compra,
                                        conversoes,
                                        valorMensal: this.valorMensal,
                                    }
                                )
                                .toDecimalPlaces(3),
                        })
                    );
                }
            }

            this.precoPorUnidades = precoPorUnidades;
        },

        rotuloPrecoPorUnidade({ unidadeId }) {
            const unidadeNome =
                this.empresa?.unidades?.find((x) => x.id == unidadeId)?.nome ??
                `unidade ${unidadeId}`;
            return `Valor ${unidadeNome}`;
        },

        obterConversoes() {
            return obterConversoesBase({
                conversaoUnidadeOrigemIds: this.conversaoUnidadeOrigemIds,
                conversaoUnidadeDestinoIds: this.conversaoUnidadeDestinoIds,
                conversaoFatores: this.conversaoFatores,
            });
        },
    };
}
