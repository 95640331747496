import Decimal from 'decimal.js';
import { notZeroy, somatorio, zeroy } from './calculos';
import { adicionarMascara as adicionarMascaraDecimal } from './decimal';
import { adicionarMascara as adicionarMascaraFloat } from './float';
import dp from './dp';

export function alpineDataCalculo(dados) {
    return {
        subTotais: {},
        adicionandoItem: false,

        ...dados,

        init() {
            this.precisaRecalculo = this.precisaRecalculo.bind(this);
            this.precisaImpostosLucro = this.precisaImpostosLucro.bind(this);
            this.rotuloImpostosExcluidos =
                this.rotuloImpostosExcluidos.bind(this);
            this.formatarPorcentagem = this.formatarPorcentagem.bind(this);
            this.preencherAliquotas = this.preencherAliquotas.bind(this);
            this.moverFocoNovoItem = this.moverFocoNovoItem.bind(this);

            this.preencherAliquotas();
        },

        get totalProduto() {
            return somatorio(
                Object.values(this.subTotais).filter(Boolean)
            ).toNumber();
        },

        get subTotalAliquotas() {
            return somatorio(
                (this.aliquotas ?? [])
                    .filter((_aliquota, indexAliquota) => {
                        const impostoId = this.impostosId[indexAliquota];

                        const imposto = this.empresa?.impostos?.find?.(
                            (x) => x.id == impostoId
                        );
                        if (!imposto) {
                            // Imposto não encontrado, então nao soma
                            return false;
                        }

                        return (
                            imposto.empresa_id == this.empresa?.id &&
                            imposto.regimes.includes(this.empresa?.regime)
                        );
                    })
                    .filter(Boolean)
            ).toNumber();
        },

        get subTotalImpostos() {
            return somatorio(
                (this.impostos ?? []).filter(Boolean)
            ).toNumber();
        },

        get subTotalOutros() {
            return new Decimal(this.frete || 0)
                .add(this.seguro || 0)
                .add(this.outro || 0)
                .toNumber();
        },

        get freteValor() {
            return new Decimal(this.frete || 0)
                .div(100)
                .mul(this.precoVenda || 0)
                .toDecimalPlaces(2)
                .toNumber();
        },

        get seguroValor() {
            return new Decimal(this.seguro || 0)
                .div(100)
                .mul(this.precoVenda || 0)
                .toDecimalPlaces(2)
                .toNumber();
        },

        get outroValor() {
            return new Decimal(this.outro || 0)
                .div(100)
                .mul(this.precoVenda || 0)
                .toDecimalPlaces(2)
                .toNumber();
        },

        get lucroValor() {
            return new Decimal(this.lucro || 0)
                .div(100)
                .mul(this.precoVenda || 0)
                .toDecimalPlaces(2)
                .toNumber();
        },

        get subTotalOutrosValor() {
            return new Decimal(this.freteValor || 0)
                .add(this.seguroValor || 0)
                .add(this.outroValor || 0)
                .toDecimalPlaces(2)
                .toNumber();
        },

        get totalGeral() {
            return new Decimal(this.subTotalAliquotas || 0)
                .add(this.subTotalOutros || 0)
                .add(this.lucro || 0)
                .toNumber();
        },

        get totalGeralValor() {
            return new Decimal(this.subTotalImpostos || 0)
                .add(this.subTotalOutrosValor || 0)
                .add(this.lucroValor || 0)
                .toDecimalPlaces(2)
                .toNumber();
        },

        get precoVenda() {
            let novoPrecoVenda = new Decimal(this.totalProduto || 0);

            const fatorDivisor = new Decimal(1).minus(
                new Decimal(this.totalGeral || 0).div(100)
            );

            if (!fatorDivisor.isZero()) {
                novoPrecoVenda = novoPrecoVenda
                    .div(fatorDivisor)
                    .toDecimalPlaces(2)
                    .toNumber();
            }

            return novoPrecoVenda;
        },

        valorPorAliquota(precoVenda, aliquota) {
            let valor = 0;

            if (notZeroy(precoVenda) && notZeroy(aliquota)) {
                valor = new Decimal(precoVenda)
                    .mul(new Decimal(aliquota).div(100))
                    .toDecimalPlaces(2)
                    .toNumber();
            }

            return valor;
        },

        get impostos() {
            return (this.aliquotas ?? []).map((aliquota) =>
                this.valorPorAliquota(this.precoVenda, aliquota)
            );
        },

        precisaRecalculo(empresa) {
            return (
                empresa &&
                empresa.ramo_atividade &&
                ((empresa.regime === 'lucro_presumido' &&
                    ['comercio', 'industria'].includes(
                        empresa.ramo_atividade
                    )) ||
                    empresa.regime === 'lucro_real')
            );
        },

        precisaImpostosLucro(empresa) {
            return empresa && empresa.regime === 'lucro_real';
        },

        get impostosExcluir() {
            return (this.empresa?.impostos ?? [])
                .filter(({ excluir_recalculo }) => !!excluir_recalculo)
                .sort((a, b) => a.nome.localeCompare(b.nome));
        },

        rotuloImpostosExcluidos(impostos) {
            return impostos.map(({ nome }) => `-${nome}`).join('');
        },

        get precoVendaReduzido() {
            const subTotalImpostosExcluir = somatorio(
                (this.impostosExcluir ?? []).map(({ id }) => {
                    const indiceValorCalculoImposto =
                        this.impostosId.indexOf(id);
                    const imposto = this.impostos[indiceValorCalculoImposto];
                    return imposto;
                })
            );

            return new Decimal(this.precoVenda || 0).sub(
                subTotalImpostosExcluir
            );
        },

        get impostosRecalcular() {
            return (this.empresa?.impostos ?? [])
                .filter(({ recalcular }) => !!recalcular)
                .sort((a, b) => a.nome.localeCompare(b.nome));
        },

        get aliquotasRecalcular() {
            return this.impostosRecalcular.map(({ id }) => {
                const indiceValorCalculoImposto = this.impostosId.indexOf(id);
                const aliquota = this.aliquotas[indiceValorCalculoImposto];
                return aliquota;
            });
        },

        get aliquotasLucro() {
            return this.dadosImpostosLucro.map(
                ({ aliquota }) => aliquota
            );
        },

        get impostosRecalculados() {
            return this.aliquotasRecalcular.map(
                (aliquota) =>
                    new Decimal(aliquota || 0)
                        .div(100)
                        .mul(this.precoVendaReduzido || 0)
                        .toDecimalPlaces(2)
                        .toNumber()
            );
        },

        get impostosLucro() {
            return this.aliquotasLucro.map((aliquota) =>
                new Decimal(aliquota || 0)
                    .div(100)
                    .mul(this.lucroFinalValor || 0)
                    .toDecimalPlaces(2)
                    .toNumber()
            );
        },

        get subTotalAliquotasRecalcular() {
            return somatorio(
                this.aliquotasRecalcular
            );
        },

        get subTotalImpostosRecalculados() {
            return somatorio(
                this.impostosRecalculados
            );
        },

        get subTotalAliquotasLucro() {
            return somatorio(this.aliquotasLucro);
        },

        get subTotalImpostosLucro() {
            return somatorio(this.impostosLucro);
        },

        get diferencasRecalculados() {
            return this.impostosRecalcular.map(
                ({ id }) => {
                    const indiceValorCalculoImposto =
                        this.impostosId.indexOf(id);
                    const original = this.impostos[indiceValorCalculoImposto];

                    const indiceImpostosRecalcular =
                        this.impostosRecalcular.findIndex((x) => x.id === id);
                    const novo =
                        this.impostosRecalculados[indiceImpostosRecalcular];

                    return new Decimal(original || 0)
                        .sub(novo || 0)
                        .toDecimalPlaces(2)
                        .toNumber();
                }
            );
        },

        get subTotalDiferencasRecalculados() {
            return somatorio(
                this.diferencasRecalculados
            );
        },

        get lucroFinalValor() {
            return new Decimal(this.lucroValor || 0).add(
                this.subTotalDiferencasRecalculados || 0
            );
        },

        get lucroFinal() {
            if (notZeroy(this.precoVenda)) {
                return new Decimal(this.lucroFinalValor || 0)
                    .div(this.precoVenda)
                    .mul(100)
                    .toDecimalPlaces(3)
                    .toNumber();
            } else {
                return 0;
            }
        },

        get lucroLiquidoFinalValor() {
            return new Decimal(this.lucroFinalValor || 0)
                .sub(this.subTotalImpostosLucro || 0)
                .toDecimalPlaces(2)
                .toNumber();
        },

        get lucroLiquidoFinal() {
            if (notZeroy(this.precoVenda)) {
                return new Decimal(this.lucroLiquidoFinalValor || 0)
                    .div(this.precoVenda)
                    .mul(100)
                    .toDecimalPlaces(3)
                    .toNumber();
            } else {
                return 0;
            }
        },

        slug(s) {
            return typeof s === 'string'
                ? s
                      .toLowerCase()
                      .replace(/[^-a-z0-9 ]/g, '')
                      .replace(/[- ]/g, '-')
                : s;
        },

        formatarDecimal(n) {
            return adicionarMascaraDecimal(n, true);
        },

        formatarFloat(n) {
            return adicionarMascaraFloat(n);
        },

        porcentagem(valor, total) {
            if (!valor || !total) {
                return 0;
            }

            return new Decimal(valor)
                .div(total)
                .mul(100)
                .toDecimalPlaces(2)
                .toNumber();
        },

        formatarPorcentagem(valor, total) {
            const p = this.porcentagem(valor, total);
            return `${this.formatarDecimal(p)}%`;
        },

        preencherAliquotas() {
            if (!this.empresa || !this.aliquotas || !this.impostosId) {
                return;
            }

            for (let i = 0; i < this.aliquotas.length; i++) {
                const aliquota = this.aliquotas[i];
                const impostoId = this.impostosId[i];

                if (notZeroy(aliquota)) {
                    // Já está preenchido, então mantém o valor atual
                    continue;
                }

                const imposto = this.empresa.impostos.find(
                    (x) => x.id == impostoId
                );
                if (!imposto) {
                    // Imposto não encontrado, então ignora
                    continue;
                }

                if (zeroy(imposto.aliquota)) {
                    // Imposto não tem alíquota definida (ou é zero), então ignora
                    continue;
                }

                this.aliquotas[i] = imposto.aliquota;
            }
        },

        moverFocoNovoItem(el) {
            if (!this.adicionandoItem) {
                return;
            }

            this.adicionandoItem = false;
            this.$focus.focus(
                el.closest('div').querySelector('.selectize-input input')
            );
        },
    };
}
