import Decimal from 'decimal.js';

export function calcularDepreciacaoMensal({ valorMaquina, depreciacaoAnual }) {
    return new Decimal(valorMaquina || 0)
        .mul(new Decimal(depreciacaoAnual || 0).div(100))
        .div(12)
        .toDecimalPlaces(2);
}

export function calcularValorMensal({
    depreciacaoMensal,
    manutencaoMensal,
    energiaEletricaMensal,
    outro,
}) {
    return new Decimal(depreciacaoMensal || 0)
        .add(manutencaoMensal || 0)
        .add(energiaEletricaMensal || 0)
        .add(outro || 0)
        .toDecimalPlaces(2);
}

export function calcularTotalHorasProdutivasMes({
    quantidade,
    manutencoes,
    troca,
}) {
    return new Decimal(quantidade || 0)
        .sub(manutencoes || 0)
        .sub(troca || 0)
        .toDecimalPlaces(2);
}

/**
 * Calcula o preço por unidade de um insumo.
 *
 * @param {string|number} unidadeId O ID da unidade cujo preço se quer calcular
 * @param {Compra} compra Objeto com os campos `quantidade`, `unidadeId`
 * @param {ConversaoUnidadeInsumo[]} conversoes Lista de conversões com os campos `unidadeOrigemId`, `unidadeDestinoId`, `fator`
 * @param {string|number} valorMensal O valor total geral calculado para esse insumo
 * @returns O preço por `unidadeId` do insumo
 */
export function calcularPrecoPorUnidade(
    { unidadeId },
    { compra, conversoes, valorMensal }
) {
    if (!compra?.quantidade) {
        return null;
    }

    const precoUnidadeCompra = new Decimal(valorMensal || 0).div(
        compra.quantidade
    );

    if (unidadeId == compra.unidadeId) {
        return precoUnidadeCompra;
    }

    for (const conversao of conversoes) {
        if (
            conversao.unidadeOrigemId == unidadeId &&
            conversao.unidadeDestinoId == compra.unidadeId
        ) {
            return precoUnidadeCompra.mul(conversao.fator);
        }

        if (
            conversao.unidadeOrigemId == compra.unidadeId &&
            conversao.unidadeDestinoId == unidadeId
        ) {
            return precoUnidadeCompra.div(conversao.fator);
        }
    }

    return null;
}
