export function buscarCep(cepSemMascara) {
    return fetch(`https://brasilapi.com.br/api/cep/v1/${cepSemMascara}`);
}

export function formatarDadosCep(dados) {
    return {
        cep: dados.cep,
        uf: dados.state,
        municipio: dados.city.toUpperCase(),
        bairro: dados.neighborhood.toUpperCase(),
        logradouro: dados.street.toUpperCase(),
    };
}

export function buscarCnpj(cnpjSemMascara) {
    return fetch(`https://brasilapi.com.br/api/cnpj/v1/${cnpjSemMascara}`);
}

export function formatarDadosCnpj(dados) {
    const logradouro = `${dados.descricao_tipo_de_logradouro} ${dados.logradouro}`;

    return {
        razao_social: dados.razao_social.toUpperCase(),
        nome_fantasia: dados.nome_fantasia.toUpperCase(),
        cep: dados.cep,
        uf: dados.uf,
        municipio: dados.municipio.toUpperCase(),
        bairro: dados.bairro.toUpperCase(),
        logradouro: logradouro.toUpperCase(),
        numero: dados.numero,
        complemento: dados.complemento,
    };
}
