export function obterConversoes({
  conversaoUnidadeOrigemIds,
  conversaoUnidadeDestinoIds,
  conversaoFatores
}) {
  const conversoes = [];
  for (
    let indiceConversao = 0;
    indiceConversao < conversaoUnidadeOrigemIds.length;
    indiceConversao++
  ) {
    const unidadeOrigemId = conversaoUnidadeOrigemIds[indiceConversao];
    const unidadeDestinoId = conversaoUnidadeDestinoIds[indiceConversao];
    const fator = conversaoFatores[indiceConversao];
    if (!unidadeOrigemId || !unidadeDestinoId || !fator) {
      continue;
    }

    conversoes.push({
      unidadeOrigemId,
      unidadeDestinoId,
      fator
    });
  }

  return conversoes;
}
