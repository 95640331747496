export default async function divulgarDadosExternos(
    event,
    valorAtual,
    { buscarDados, nomeEvento, nomeCampo }
) {
    const valor = event.target.value;
    if (valor === valorAtual) {
        return;
    }

    event.target.dispatchEvent(
        new Event(`${nomeEvento}-carregando`, { bubbles: true })
    );
    try {
        const dados = await buscarDados(valor);
        if (dados) {
            const evento = new Event(nomeEvento, {
                bubbles: true,
            });
            evento[nomeCampo] = dados;
            event.target.dispatchEvent(evento);
        } else {
            event.target.dispatchEvent(
                new Event(`${nomeEvento}-nao-encontrado`, { bubbles: true })
            );
        }
    } finally {
        event.target.dispatchEvent(
            new Event(`${nomeEvento}-carregou`, { bubbles: true })
        );
    }
}
