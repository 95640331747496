import Decimal from 'decimal.js';

export function calcularSubTotalImpostos({ impostos }) {
    return (impostos ?? [])
        .filter(Boolean)
        .reduce(
            (subTotal, imposto) => new Decimal(subTotal).add(imposto),
            new Decimal(0)
        );
}

export function calcularSubTotalOutros({ frete, seguro, outro }) {
    return new Decimal(frete || 0).add(seguro || 0).add(outro || 0);
}

export function calcularTotalGeral({
    empresa,
    valorTotal,
    subTotalImpostos,
    subTotalOutros,
}) {
    if (!empresa) {
        return new Decimal(0);
    }

    const fatorImpostos = empresa.regime === 'simples' ? 1 : -1;
    return new Decimal(valorTotal || 0)
        .add(new Decimal(fatorImpostos).mul(subTotalImpostos || 0))
        .add(subTotalOutros || 0);
}

/**
 * Calcula o preço por unidade de um insumo.
 *
 * @param {string|number} unidadeId O ID da unidade cujo preço se quer calcular
 * @param {Compra} compra Objeto com os campos `quantidade`, `unidadeId`
 * @param {ConversaoUnidadeInsumo[]} conversoes Lista de conversões com os campos `unidadeOrigemId`, `unidadeDestinoId`, `fator`
 * @param {string|number} totalGeral O valor total geral calculado para esse insumo
 * @returns O preço por `unidadeId` do insumo
 */
export function calcularPrecoPorUnidade(
    { unidadeId },
    { compra, conversoes, totalGeral }
) {
    if (!compra?.quantidade) {
        return null;
    }

    const precoUnidadeCompra = new Decimal(totalGeral || 0).div(
        compra.quantidade
    );

    if (unidadeId == compra.unidadeId) {
        return precoUnidadeCompra;
    }

    for (const conversao of conversoes) {
        if (
            conversao.unidadeOrigemId == unidadeId &&
            conversao.unidadeDestinoId == compra.unidadeId
        ) {
            return precoUnidadeCompra.mul(conversao.fator);
        }

        if (
            conversao.unidadeOrigemId == compra.unidadeId &&
            conversao.unidadeDestinoId == unidadeId
        ) {
            return precoUnidadeCompra.div(conversao.fator);
        }
    }

    return null;
}
