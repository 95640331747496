import Decimal from 'decimal.js';
import {
    calcularPrecoPorUnidade,
    calcularSubTotalImpostosRemuneracaoPessoal,
    calcularSubTotalOutros,
    calcularSubTotalSalario,
    calcularTotalGeral,
} from './comum-pessoal';

export function alpineDataCalculoPessoal(dados) {
    return {
        ...dados,

        init() {
            this.adicionar = this.adicionar.bind(this);
            this.valorUnidadeSelecionada =
                this.valorUnidadeSelecionada.bind(this);
            this.totalItem = this.totalItem.bind(this);

            (this.insumoCalculos ?? []).forEach((x) => {
                x.key = x.key ?? this.$id('insumoCalculos-material');
            });
        },

        adicionar() {
            this.adicionandoItem = true;
            this.insumoCalculos.push({
                quantidade: '',
                key: this.$id('insumoCalculos-pessoal'),
            });
        },

        remover(indice) {
            this.insumoCalculos.splice(indice, 1);
        },

        valorUnidadeSelecionada(insumo, unidade_id) {
            if (!unidade_id) {
                return null;
            }

            const compra = insumo?.compras?.[0];

            const salarioBrutoMensal =
                compra?.valorCompraSalarioBrutoMensal?.valor;

            const impostosEncargoSocial = compra?.valorComprasImpostos
                ?.filter((x) => x.imposto.incidencia === 'encargo_social')
                ?.map((x) => x.valor)
                ?.map((aliquota) =>
                    new Decimal(aliquota || 0)
                        .div(100)
                        .mul(salarioBrutoMensal || 0)
                );

            const subTotalSalario = calcularSubTotalSalario({
                impostosEncargoSocial,
                salarioBrutoMensal,
            });

            const impostosRemuneracaoPessoal = compra?.valorComprasImpostos
                ?.filter((x) => x.imposto.incidencia === 'remuneracao_pessoal')
                ?.map((x) => x.valor)
                ?.map((aliquota) =>
                    new Decimal(aliquota || 0)
                        .div(100)
                        .mul(subTotalSalario || 0)
                );

            const subTotalImpostos = calcularSubTotalImpostosRemuneracaoPessoal(
                {
                    impostosRemuneracaoPessoal,
                }
            );

            const v = calcularPrecoPorUnidade(
                {
                    unidadeId: unidade_id,
                },
                {
                    compra: {
                        quantidade: compra?.quantidade,
                        unidadeId: compra?.unidade_id,
                    },
                    conversoes: insumo?.conversao_unidade_insumos?.map(
                        ({ unidade_origem_id, unidade_destino_id, fator }) => ({
                            unidadeOrigemId: unidade_origem_id,
                            unidadeDestinoId: unidade_destino_id,
                            fator,
                        })
                    ),
                    totalGeral: calcularTotalGeral({
                        subTotalSalario,
                        subTotalImpostos,
                        subTotalOutros: calcularSubTotalOutros({
                            beneficiosMensais:
                                compra?.valorCompraBeneficiosMensais?.valor,
                            outro: compra?.valorCompraOutro?.valor,
                        }),
                    }),
                }
            );

            return v.toDecimalPlaces(3).toNumber();
        },

        totalItem(insumo, quantidade, unidade_id) {
            const v = this.valorUnidadeSelecionada(insumo, unidade_id);
            const q = quantidade;
            return new Decimal(v || 0).mul(q || 0).toNumber();
        },

        subTotal(insumoCalculos) {
            const valor = insumoCalculos
                .reduce((total, insumoCalculo) => {
                    const t = this.totalItem(
                        this.insumos[insumoCalculo.insumo_id],
                        insumoCalculo.quantidade,
                        insumoCalculo.unidade_id
                    );
                    return total.add(t);
                }, new Decimal(0))
                .toDecimalPlaces(3)
                .toNumber();

            this.$dispatch('calculou-sub-total', {
                valor,
                tipoInsumoId: this.tipoInsumoId,
            });

            return valor;
        },
    };
}
