const formato = new Intl.NumberFormat('pt-BR');

const formatoComFracao = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
});

const simbolos = Object.fromEntries(
    formato.formatToParts(1111.11).map(({ type, value }) => [type, value])
);

export function removerMascara(value) {
    if (!value) {
        return value;
    }

    return value
        .replaceAll(simbolos.group, '')
        .replaceAll(simbolos.decimal, '.');
}

/**
 * Formata um valor com a máscara decimal.
 *
 * @param {number|string} value O valor a ser formatado
 * @param {boolean} comFracao Indica que deve incluir a parte fracionada mesmo que não significativa
 * @returns O valor formatado ou o valor original caso seja falsy
 */
export function adicionarMascara(value, comFracao) {
    if (
        value === undefined ||
        value === null ||
        value === false ||
        value === ''
    ) {
        return value;
    }

    return (comFracao ? formatoComFracao : formato).format(Number(value));
}

export function alpineDataComponentDecimal(dados) {
    return {
        decimalComMascara: '',
        ...dados,

        handleInput($event) {
            if (removerMascara($event.target.value) != this.decimalSemMascara) {
                // Converte para padrão JS e atualiza hidden
                this.decimalSemMascara = removerMascara($event.target.value);
                this.$refs.hiddenValue.value = this.decimalSemMascara;
                this.$refs.hiddenValue.dispatchEvent(new Event('input', { bubbles: true }));
            }
        },
    }
}
