import Decimal from 'decimal.js';
import { camposDecimalParaNumber } from './calculos';
import { calcularImpostos as calcularImpostosBase } from './impostos';
import * as baseComum from './comum-material';
import { obterConversoes as obterConversoesBase } from './comum';

export function alpineDataInsumoMaterial(dados) {
    return {
        impostos: [],
        subTotalImpostos: '0',
        subTotalOutros: '0',
        totalGeral: '0',
        precoPorUnidades: [],
        ...dados,

        init() {
            this.calcularImpostos = this.calcularImpostos.bind(this);
            this.calcularSubTotalImpostos =
                this.calcularSubTotalImpostos.bind(this);
            this.calcularSubTotalOutros =
                this.calcularSubTotalOutros.bind(this);
            this.calcularTotalGeral = this.calcularTotalGeral.bind(this);
            this.calcularPrecoPorUnidade =
                this.calcularPrecoPorUnidade.bind(this);
            this.obterConversoes = this.obterConversoes.bind(this);
            this.rotuloPrecoPorUnidade = this.rotuloPrecoPorUnidade.bind(this);
            this.nomeUnidade = this.nomeUnidade.bind(this);
            this.atualizarUnidadeCompra = this.atualizarUnidadeCompra.bind(this);
            this.atualizarUnidadeUso = this.atualizarUnidadeUso.bind(this);
            this.inverterUnidadesConversao = this.inverterUnidadesConversao.bind(this);

            this.$watch(['valorTotal', 'empresa?.id'], this.calcularImpostos);
            this.$watch('aliquotas', this.calcularImpostos);

            this.$watch('impostos', this.calcularSubTotalImpostos);

            this.$watch(
                ['frete', 'seguro', 'outro'],
                this.calcularSubTotalOutros
            );

            this.$watch(
                ['empresa', 'valorTotal', 'subTotalImpostos', 'subTotalOutros'],
                this.calcularTotalGeral
            );

            this.$watch(
                ['quantidade', 'totalGeral', 'compraUnidadeId'],
                this.calcularPrecoPorUnidade
            );

            // $watch de arrays precisa ser individual
            this.$watch(
                'conversaoUnidadeOrigemIds',
                this.calcularPrecoPorUnidade
            );
            this.$watch(
                'conversaoUnidadeDestinoIds',
                this.calcularPrecoPorUnidade
            );
            this.$watch('conversaoFatores', this.calcularPrecoPorUnidade);

            this.$watch('compraUnidadeId', this.atualizarUnidadeCompra);

            this.$watch('unidadeUsoId', this.atualizarUnidadeUso);

            this.calcularImpostos();
            this.calcularSubTotalImpostos();
            this.calcularSubTotalOutros();
            this.calcularTotalGeral();
            this.calcularPrecoPorUnidade();
            this.atualizarUnidadeCompra(dados.compraUnidadeId, null);
            this.atualizarUnidadeUso(dados.unidadeUsoId, null);
        },

        calcularImpostos() {
            calcularImpostosBase({
                data: this,
                aliquotas: this.aliquotas,
                campoImpostos: 'impostos',
                idEmpresas: this.empresas,
                idEmpresaSelecionada: this.empresa?.id,
                baseCalculo: this.valorTotal,
            });
        },

        calcularSubTotalImpostos() {
            this.subTotalImpostos = baseComum
                .calcularSubTotalImpostos({ impostos: this.impostos })
                .toNumber();
        },

        calcularSubTotalOutros() {
            this.subTotalOutros = baseComum
                .calcularSubTotalOutros({
                    frete: this.frete,
                    seguro: this.seguro,
                    outro: this.outro,
                })
                .toNumber();
        },

        calcularTotalGeral() {
            this.totalGeral = baseComum
                .calcularTotalGeral({
                    empresa: this.empresa,
                    valorTotal: this.valorTotal,
                    subTotalImpostos: this.subTotalImpostos,
                    subTotalOutros: this.subTotalOutros,
                })
                .toNumber();
        },

        calcularPrecoPorUnidade() {
            if (
                !this.quantidade ||
                new Decimal(this.quantidade).isZero() ||
                !this.compraUnidadeId
            ) {
                this.precoPorUnidades = [];
                return;
            }

            const compra = {
                quantidade: this.quantidade,
                unidadeId: this.compraUnidadeId,
            };

            const conversoes = this.obterConversoes();

            const precoPorUnidades = [
                camposDecimalParaNumber({
                    unidadeId: this.compraUnidadeId,
                    preco: baseComum
                        .calcularPrecoPorUnidade(
                            { unidadeId: this.compraUnidadeId },
                            {
                                compra,
                                conversoes,
                                totalGeral: this.totalGeral,
                            }
                        )
                        .toDecimalPlaces(3),
                }),
            ];

            for (
                let indiceConversao = 0;
                indiceConversao < this.conversaoUnidadeOrigemIds.length;
                indiceConversao++
            ) {
                const unidadeOrigemId =
                    this.conversaoUnidadeOrigemIds[indiceConversao];
                const unidadeDestinoId =
                    this.conversaoUnidadeDestinoIds[indiceConversao];
                const fator = this.conversaoFatores[indiceConversao];
                if (!unidadeOrigemId || !unidadeDestinoId || !fator) {
                    continue;
                }

                if (unidadeOrigemId == this.compraUnidadeId) {
                    precoPorUnidades.push(
                        camposDecimalParaNumber({
                            unidadeId: unidadeDestinoId,
                            preco: baseComum
                                .calcularPrecoPorUnidade(
                                    { unidadeId: unidadeDestinoId },
                                    {
                                        compra,
                                        conversoes,
                                        totalGeral: this.totalGeral,
                                    }
                                )
                                .toDecimalPlaces(3),
                        })
                    );
                } else if (unidadeDestinoId == this.compraUnidadeId) {
                    precoPorUnidades.push(
                        camposDecimalParaNumber({
                            unidadeId: unidadeOrigemId,
                            preco: baseComum
                                .calcularPrecoPorUnidade(
                                    { unidadeId: unidadeOrigemId },
                                    {
                                        compra,
                                        conversoes,
                                        totalGeral: this.totalGeral,
                                    }
                                )
                                .toDecimalPlaces(3),
                        })
                    );
                }
            }

            this.precoPorUnidades = precoPorUnidades;
        },

        atualizarUnidadeCompra(unidadeNova, unidadeAntiga) {
            if (unidadeAntiga == this.conversaoUnidadeOrigemIds[0]) {
                this.conversaoUnidadeOrigemIds[0] = unidadeNova;
            } else {
                this.conversaoUnidadeDestinoIds[0] = unidadeNova;
            }
        },

        atualizarUnidadeUso(unidadeNova, unidadeAntiga) {
            if (unidadeAntiga == this.conversaoUnidadeOrigemIds[0]) {
                this.conversaoUnidadeOrigemIds[0] = unidadeNova;
            } else {
                this.conversaoUnidadeDestinoIds[0] = unidadeNova;
            }
        },

        inverterUnidadesConversao() {
            [this.conversaoUnidadeOrigemIds[0], this.conversaoUnidadeDestinoIds[0]] = [this.conversaoUnidadeDestinoIds[0], this.conversaoUnidadeOrigemIds[0]];
        },

        nomeUnidade(unidadeId) {
            if (!unidadeId) {
                return '-';
            }

            return (
                this.empresa?.unidades?.find((x) => x.id == unidadeId)
                    ?.simbolo ?? String(unidadeId)
            );
        },

        rotuloPrecoPorUnidade({ unidadeId }) {
            const unidadeNome = this.nomeUnidade(unidadeId);

            return unidadeId == this.compraUnidadeId
                ? `Preço por ${unidadeNome}`
                : `Preço por Fator (${unidadeNome})`;
        },

        obterConversoes() {
            return obterConversoesBase({
                conversaoUnidadeOrigemIds: this.conversaoUnidadeOrigemIds,
                conversaoUnidadeDestinoIds: this.conversaoUnidadeDestinoIds,
                conversaoFatores: this.conversaoFatores,
            });
        },
    };
}
