/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
import SimpleMaskMoney from 'simple-mask-money';
import * as moduloCnpj from './cnpj';
import * as moduloCep from './cep';
import * as moduloFloat from './float';
import * as moduloDecimal from './decimal';
import jQuery from 'jquery';
import selectize from '@selectize/selectize';
import * as moduloFiles from './files';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.SimpleMaskMoney = SimpleMaskMoney;

window.moduloCnpj = moduloCnpj;

window.moduloCep = moduloCep;

window.moduloFloat = moduloFloat;

window.moduloDecimal = moduloDecimal;

window.moduloFiles = moduloFiles;

window.jQuery = jQuery;
window.selectize = selectize;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });

window.forceUpperCase = (event) => {
    const input = event.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    input.value = input.value.toUpperCase();
    input.setSelectionRange(start, end);
};

window.handleDelete = (event) => {
    const form = event.target.closest('form');
    const methodInput = form.querySelector('input[name=_method]');
    methodInput.value = 'DELETE';

    form.submit();
};

window.handleRestore = (event) => {
    const form = event.target.closest('form');
    const methodInput = form.querySelector('input[name=_method]');
    methodInput.value = 'POST';

    if (!form.action.endsWith('/restore')) {
        form.action = `${form.action}/restore`;
    }

    return false;
};
