import Decimal from 'decimal.js';
import { notZeroy } from './calculos';
import {
    calcularPrecoPorUnidade,
    calcularSubTotalImpostos,
    calcularSubTotalOutros,
    calcularTotalGeral,
} from './comum-material';
import dp from './dp';

export function alpineDataCalculoMaterial(dados) {
    return {
        ...dados,

        init() {
            this.adicionar = this.adicionar.bind(this);
            this.unidadeUso = this.unidadeUso.bind(this);
            this.unidadeCompra = this.unidadeCompra.bind(this);
            this.unidadeUsoSimbolo = this.unidadeUsoSimbolo.bind(this);
            this.unidadeCompraSimbolo = this.unidadeCompraSimbolo.bind(this);
            this.unidadeUsoId = this.unidadeUsoId.bind(this);
            this.unidadeCompraId = this.unidadeCompraId.bind(this);
            this.fator = this.fator.bind(this);
            this.quantidadeUnidadeCompra =
                this.quantidadeUnidadeCompra.bind(this);
            this.valorUnidadeUso = this.valorUnidadeUso.bind(this);
            this.totalItem = this.totalItem.bind(this);

            (this.insumoCalculos ?? []).forEach((x) => {
                x.key = x.key ?? this.$id('insumoCalculos-material');
            });
        },

        adicionar() {
            this.adicionandoItem = true;
            this.insumoCalculos.push({
                quantidade: '',
                key: this.$id('insumoCalculos-material'),
            });
        },

        remover(indice) {
            this.insumoCalculos.splice(indice, 1);
        },

        unidadeCompra(insumo) {
            return insumo?.compras?.[0]?.unidade;
        },

        unidadeUso(insumo) {
            const unidadeCompra = this.unidadeCompra(insumo);

            if (insumo?.conversao_unidade_insumos?.length > 0) {
                const conversao = insumo.conversao_unidade_insumos[0];

                return conversao.unidade_origem?.id == unidadeCompra?.id
                    ? conversao.unidade_destino
                    : conversao.unidade_origem;
            } else {
                return unidadeCompra;
            }
        },

        unidadeCompraSimbolo(insumo) {
            return this.unidadeCompra(insumo)?.simbolo;
        },

        unidadeUsoSimbolo(insumo) {
            return this.unidadeUso(insumo)?.simbolo;
        },

        unidadeCompraId(insumo) {
            return this.unidadeCompra(insumo)?.id;
        },

        unidadeUsoId(insumo) {
            return this.unidadeUso(insumo)?.id;
        },

        fator(insumo) {
            return insumo?.conversao_unidade_insumos?.[0]?.fator;
        },

        quantidadeUnidadeCompra(insumo, quantidade) {
            const f = this.fator(insumo);
            const q = notZeroy(f) ? new Decimal(quantidade || 0).div(f) : null;
            return q ? q.toDecimalPlaces(3).toNumber() : null;
        },

        valorUnidadeUso(insumo) {
            const compra = insumo?.compras?.[0];

            const valorTotal = compra?.valorCompraValorInsumo?.valor;

            const subTotalImpostos = calcularSubTotalImpostos({
                impostos: compra?.valorComprasImpostos
                    ?.map((x) => x.valor)
                    ?.map((aliquota) =>
                        new Decimal(aliquota || 0).div(100).mul(valorTotal || 0)
                    ),
            });

            const subTotalOutros = calcularSubTotalOutros({
                frete: compra?.valorCompraFrete?.valor,
                seguro: compra?.valorCompraSeguro?.valor,
                outro: compra?.valorCompraOutro?.valor,
            });

            const totalGeral = calcularTotalGeral({
                empresa: this.empresa,
                valorTotal,
                subTotalImpostos,
                subTotalOutros,
            });

            const v = calcularPrecoPorUnidade(
                {
                    unidadeId: this.unidadeUsoId(insumo),
                },
                {
                    compra: {
                        quantidade: compra?.quantidade,
                        unidadeId: compra?.unidade_id,
                    },
                    conversoes: insumo?.conversao_unidade_insumos?.map(
                        ({ unidade_origem_id, unidade_destino_id, fator }) => ({
                            unidadeOrigemId: unidade_origem_id,
                            unidadeDestinoId: unidade_destino_id,
                            fator,
                        })
                    ),
                    totalGeral,
                }
            );

            return v ? v.toDecimalPlaces(3).toNumber() : null;
        },

        totalItem(insumo, quantidade) {
            const v = this.valorUnidadeUso(insumo);
            return new Decimal(v || 0).mul(quantidade || 0).toNumber();
        },

        subTotal(insumoCalculos) {
            const valor = insumoCalculos
                .reduce((total, insumoCalculo) => {
                    const t = this.totalItem(
                        this.insumos[insumoCalculo.insumo_id],
                        insumoCalculo.quantidade
                    );
                    return total.add(t);
                }, new Decimal(0))
                .toDecimalPlaces(3)
                .toNumber();

            this.$dispatch('calculou-sub-total', {
                valor,
                tipoInsumoId: this.tipoInsumoId,
            });

            return valor;
        },
    };
}
