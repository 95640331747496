import { buscarCnpj, formatarDadosCnpj } from './brasilapi';
import DadosExternos from './dados-externos';
import divulgarDadosExternos from './divulgar-dados-externos';

export function removerMascara(value) {
    if (!value) {
        return value;
    }

    return value.replace(/\D/g, '');
}

export function adicionarMascara(value) {
    if (!value) {
        return value;
    }

    value = removerMascara(value);

    return [
        value.slice(0, 2),
        '.',
        value.slice(2, 5),
        '.',
        value.slice(5, 8),
        '/',
        value.slice(8, 12),
        '-',
        value.slice(12, 14),
    ].join('');
}

export function cnpjValidoBusca(cnpjSemMascara) {
    return cnpjSemMascara && /^\d{14}$/.test(cnpjSemMascara);
}

export function prepararCnpjBusca(cnpj) {
    const cnpjSemMascara = removerMascara(cnpj);
    if (!cnpjValidoBusca(cnpjSemMascara)) {
        return null;
    }

    return cnpjSemMascara;
}

const dadosCnpj = new DadosExternos({
    cache: {
        11111111111111: {
            razao_social: 'DIOGO KOLLROSS SISTEMA DA INFORMACAO',
            nome_fantasia: 'DIOGO KOLLROSS SISTEMAS',
            cep: '15056750',
            uf: 'SP',
            municipio: 'SAO JOSE DO RIO PRETO',
            bairro: 'LIBERDADE VI - PQ. DA',
            logradouro: 'AVENIDA JOSE DA SILVA SE',
            numero: '505',
            complemento: 'CASA CS 121',
        },
    },

    prepararValorBusca: prepararCnpjBusca,

    executarRequisicao: buscarCnpj,

    formatarDados: formatarDadosCnpj,
});

export async function buscarEmpresa(cnpj) {
    return dadosCnpj.buscar(cnpj);
}

export async function divulgarEmpresa(event, empresaAtual) {
    await divulgarDadosExternos(event, empresaAtual?.cnpj, {
        buscarDados: buscarEmpresa,
        nomeEvento: 'divulgar-empresa',
        nomeCampo: 'empresa',
    });
}
