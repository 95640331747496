import Decimal from 'decimal.js';

export function calcularSubTotalSalario({
    impostosEncargoSocial,
    salarioBrutoMensal,
}) {
    const encargos = (impostosEncargoSocial ?? [])
        .filter(Boolean)
        .reduce(
            (subTotal, imposto) => new Decimal(subTotal).add(imposto),
            new Decimal(0)
        )
        .toNumber();

    return new Decimal(salarioBrutoMensal || 0)
        .plus(encargos)
        .toDecimalPlaces(2);
}

export function calcularSubTotalImpostosRemuneracaoPessoal({
    impostosRemuneracaoPessoal,
}) {
    return (impostosRemuneracaoPessoal ?? [])
        .filter(Boolean)
        .reduce(
            (subTotal, imposto) => new Decimal(subTotal).add(imposto),
            new Decimal(0)
        )
        .toDecimalPlaces(2);
}

export function calcularSubTotalOutros({ beneficiosMensais, outro }) {
    return new Decimal(beneficiosMensais || 0)
        .add(outro || 0)
        .toDecimalPlaces(2);
}

export function calcularTotalGeral({
    subTotalSalario,
    subTotalImpostos,
    subTotalOutros,
}) {
    return new Decimal(subTotalSalario || 0)
        .add(subTotalImpostos || 0)
        .add(subTotalOutros || 0)
        .toDecimalPlaces(2);
}

/**
 * Calcula o preço por unidade de um insumo.
 *
 * @param {string|number} unidadeId O ID da unidade cujo preço se quer calcular
 * @param {Compra} compra Objeto com os campos `quantidade`, `unidadeId`
 * @param {ConversaoUnidadeInsumo[]} conversoes Lista de conversões com os campos `unidadeOrigemId`, `unidadeDestinoId`, `fator`
 * @param {string|number} totalGeral O valor total geral calculado para esse insumo
 * @returns O preço por `unidadeId` do insumo
 */
export function calcularPrecoPorUnidade(
    { unidadeId },
    { compra, conversoes, totalGeral }
) {
    if (!compra?.quantidade) {
        return null;
    }

    const precoUnidadeCompra = new Decimal(totalGeral || 0).div(
        compra.quantidade
    );

    if (unidadeId == compra.unidadeId) {
        return precoUnidadeCompra;
    }

    for (const conversao of conversoes) {
        if (
            conversao.unidadeOrigemId == unidadeId &&
            conversao.unidadeDestinoId == compra.unidadeId
        ) {
            return precoUnidadeCompra.mul(conversao.fator);
        }

        if (
            conversao.unidadeOrigemId == compra.unidadeId &&
            conversao.unidadeDestinoId == unidadeId
        ) {
            return precoUnidadeCompra.div(conversao.fator);
        }
    }

    return null;
}
