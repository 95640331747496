import { buscarCep, formatarDadosCep } from './brasilapi';
import DadosExternos from './dados-externos';
import divulgarDadosExternos from './divulgar-dados-externos';

export function removerMascara(value) {
    if (!value) {
        return value;
    }

    return value.replace(/\D/g, '');
}

export function adicionarMascara(value) {
    if (!value) {
        return value;
    }

    value = removerMascara(value);

    return [
        value.slice(0, 2),
        '.',
        value.slice(2, 5),
        '-',
        value.slice(5, 8),
    ].join('');
}

export function cepValidoBusca(cepSemMascara) {
    return cepSemMascara && /^\d{8}$/.test(cepSemMascara);
}

export function prepararCepBusca(cep) {
    const cepSemMascara = removerMascara(cep);
    if (!cepValidoBusca(cepSemMascara)) {
        return null;
    }

    return cepSemMascara;
}

const dadosCep = new DadosExternos({
    cache: {
        11111111: {
            cep: '15056670',
            uf: 'SP',
            municipio: 'São José do Rio Preto',
            bairro: 'Condomínio Residencial Parque da Liberdade VI',
            logradouro: 'Avenida José da Silva Sé',
        },
    },

    prepararValorBusca: prepararCepBusca,

    executarRequisicao: buscarCep,

    formatarDados: formatarDadosCep,
});

export async function buscarEndereco(cep) {
    return dadosCep.buscar(cep);
}

export async function divulgarEndereco(event, enderecoAtual) {
    await divulgarDadosExternos(event, enderecoAtual?.cep, {
        buscarDados: buscarEndereco,
        nomeEvento: 'divulgar-endereco',
        nomeCampo: 'endereco',
    });
}
