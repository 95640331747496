import './bootstrap';

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import mask from '@alpinejs/mask';
import { alpineDataInsumoMaterial } from './insumo-material';
import { alpineDataInsumoPessoal } from './insumo-pessoal';
import { alpineDataInsumoMaquinario } from './insumo-maquinario';
import LogRocket from 'logrocket';
import { alpineDataCalculoMaterial } from './calculo-material';
import { alpineDataCalculoPessoal } from './calculo-pessoal';
import { alpineDataCalculoMaquinario } from './calculo-maquinario';
import { alpineDataCalculo } from './calculo';
import { alpineDataComponentDecimal } from './decimal';

if (window.environment === 'production') {
    LogRocket.init('zsrydc/icustos');
    if (window.user) {
        LogRocket.identify(String(user.id), {
            name: user.name,
            email: user.email,
        });
    }
}

window.Alpine = Alpine;

Alpine.plugin(focus);
Alpine.plugin(mask);

Alpine.data('insumoMaterial', alpineDataInsumoMaterial);
Alpine.data('insumoPessoal', alpineDataInsumoPessoal);
Alpine.data('insumoMaquinario', alpineDataInsumoMaquinario);
Alpine.data('calculo', alpineDataCalculo);
Alpine.data('calculoMaterial', alpineDataCalculoMaterial);
Alpine.data('calculoPessoal', alpineDataCalculoPessoal);
Alpine.data('calculoMaquinario', alpineDataCalculoMaquinario);
Alpine.data('componentDecimal', alpineDataComponentDecimal);

Alpine.start();
