const simbolos = Object.fromEntries(
    new Intl.NumberFormat('pt-BR')
        .formatToParts(1111.11)
        .map(({ type, value }) => [type, value])
);

export function removerMascara(value) {
    if (!value) {
        return value;
    }

    return value
        .replaceAll(simbolos.group, '')
        .replaceAll(simbolos.decimal, '.');
}

/**
 * Formata um valor com a máscara float.
 *
 * @param {number|string} value O valor a ser formatado
 * @param {boolean} comFracao Indica que deve incluir a parte fracionada mesmo que não significativa
 * @returns O valor formatado ou o valor original caso seja falsy
 */
export function adicionarMascara(value, comFracao) {
    if (
        value === undefined ||
        value === null ||
        value === false ||
        value === ''
    ) {
        return value;
    }

    return String(value)
        .replace(/\.([0-9]+?)0+$/, ',$1')
        .replace(/^([0-9]+)$/, comFracao ? '$1,0' : '$1')
        .replace('.', simbolos.decimal);
}
