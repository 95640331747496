export default class DadosExternos {
    constructor({
        cache,
        prepararValorBusca,
        executarRequisicao,
        formatarDados,
    }) {
        this.cache = cache ?? {};
        this.prepararValorBusca = prepararValorBusca ?? ((valor) => valor);
        this.executarRequisicao = executarRequisicao;
        this.formatarDados = formatarDados ?? ((valor) => valor);

        this.buscar = this.buscar.bind(this);
    }

    async buscar(valor) {
        const valorPreparado = this.prepararValorBusca(valor);
        if (!valorPreparado) {
            return null;
        }

        if (this.cache[valorPreparado]) {
            return this.cache[valorPreparado];
        }

        const response = await this.executarRequisicao(valorPreparado);
        if (response.status !== 200) {
            return null;
        }

        const dados = await response.json();

        const dadosFormatados = this.formatarDados(dados);

        this.cache[valorPreparado] = dadosFormatados;

        return dadosFormatados;
    }
}
